<template>
  <el-dialog :close-on-click-modal="false" :width="`${width}px`" :title="title" :visible="show" @close="_Switch">
    <GlobalNav
        v-if="nav.navList.length"
        :navList="nav.navList"
        :initNav="nav.navList[0]"
        @changeNav="nav.changeNav"
        class="mt20"
    />
    <GlobalForm
        v-if="form.formItemList.length"
        :init-data="form.initData||null"
        :form-rules="form.formRules"
        :form-item-list="form.formItemList"
        :inline="true"
        :round="form.round"
        :labelWidth="form.labelWidth"
        @handleConfirm="form.handleConfirm"
        :confirmBtnName="form.confirmName || '查询'"
    />
    <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="tableColumns"
        :data="tableData"
        :currentPage="page.pageNum"
        :total="page.total"
        @handleCurrentChange="handleCurrentChange"
    >
      <slot></slot>
    </GlobalTable>
  </el-dialog>
</template>

<script>
import MinXinRequest from '@/mixins/request'
const defaultWidth = 1400
export default {
  name: 'GlobalDialogTable',
  mixins: [MinXinRequest],
  data() {
    return {
      title: '--',
      show: false,
      width: defaultWidth,
      tableColumns: [],
      tableData: [],
      reqUrl: '',
      filterFunc: () => {},
      handleFunc: () => {},
      form: {
        initData: null,
        formItemList: [],
        formRules: {},
        labelWidth: 70,
        handleConfirm: () => {}
      },
      nav: {
        navList: [],
        changeNav: ()=>{}
      }
    }
  },
  methods: {
    _Switch(baseInfo={}) {
      this.show = !this.show
      // open
      if (this.show) {
        if (!baseInfo.reqUrl) return console.error('reqUrl为必选项，相关数据->', baseInfo)
        this.reqUrl = baseInfo.reqUrl
        this.title = baseInfo.title
        this.tableColumns = baseInfo.tableColumns
        this.tableData = baseInfo.tableData
        this.width = baseInfo.width || defaultWidth
        this.form = Object.assign(this.form, (baseInfo.form||{}))
        this.nav = Object.assign(this.nav, (baseInfo.nav||{}))
      }
      // close
      if (!this.show) {
        Object.assign(this.$data, this.$options.data())
      }
    },
    handleCurrentChange(val) {
      this.params.pageNum = val
      this.reqList()
    },
    reqList() {
      this.loading = true
      // 1. 请求...
      // 1.1 请求需要处理的值...
      this.filterFunc()
      this.loading = false
    },
    handleBtn(row, type) {
      this.handleFunc(row, type)
    },
    changeNav(item) {
      console.log('当前切换的Nav', item)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">

</style>
