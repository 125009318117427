<template>
  <div class="global-chunk">
    <div v-if="title" class="global-chunk_header">
      <div class="global-chunk_header_title">
        <img v-if="icon==='search'" src="@/assets/images/icon_ss@2x.png" alt>
        <img v-if="icon==='list'" src="@/assets/images/iocn_ddlb@2x.png" alt>
        <img v-if="icon==='add'" src="@/assets/images/icon_tjsj@2x.png" alt>
        <img v-if="icon==='order'" src="@/assets/images/icon_lb@2x.png" alt>
        <img v-if="icon==='edit'" src="@/assets/images/icon_bj@2x.png" alt>
        <div v-if="icon==='vertical'" class="vertical"></div>
        <span>{{title}}</span>
        <slot name="tip"></slot>
        <div class="global-chunk_header_title_btn">
          <slot name="header"></slot>
        </div>
      </div>

      <div v-if="isFilter" class="global-chunk_header_filter">
        <slot name="filter" class="global-chunk_header_filter"></slot>
      </div>
    </div>

    <div class="global-chunk_content" :style="`padding: ${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px;`">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalChunk',
  props: {
    padding: {
      type: Array,
      default: () => {
        return [20, 20, 20, 20]
      }
    },
    title: String,
    // icon: search/list/add/order
    icon: String,
    isFilter: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.global-chunk {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  &_header{
    border-bottom: 1px solid #EDEFFD;
    &_title{
      height: 56px;
      display: flex; align-items: center;
      padding: 0 20px;
      >img{
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
      >span{
        font-size: 18px;
        color: #333333;
        font-weight: bold;
      }

      &_btn{
        flex: 1;
        text-align: right;
      }
    }

    &_filter{
      padding: 12px 20px 0 10px;
    }
  }
  .vertical{
    width: 4px;
    height: 18px;
    background: #4C84FF;
    border-radius: 2px;
    margin-right: 8px;
  }
}
</style>
