<template>
	<div class="el-form-group" :class="round&&'el-form-group_isRound'">
		<el-form
			ref="validForm"
			:inline="inline"
			:size="size"
			:model="formData"
			:rules="formRules"
			:label-width="labelWidth + 'px'"
		>
			<!--eslint-disable-->
			<el-form-item
				v-for="(item,index) in formItemList"
				v-if="!deleteArr.includes(item.key)"
				:key="index"
				:label="item.labelName"
				:prop="item.key"
				:label-width="item.lableWidth?item.lableWidth:'85px'"
			>
				<component
					:is="item.component"
					v-if="item.component"
				/>
				<slot
					v-if="item.slotName"
					:name="item.slotName"
				/>
				<el-input
					v-if="item.type === 'input' && !item.isNumber && !item.clear"
					v-model="formData[item.key]"
					:placeholder="item.placeholder"
					:disabled="item.readonly"
					:type="item.password ? 'password' : 'text'"
					clearable
				/>
				<el-input
					v-if="item.type === 'input' && !item.isNumber && item.clear"
					v-model="formData[item.key]"
					:placeholder="item.placeholder"
					:disabled="item.readonly"
					:type="item.password ? 'password' : 'text'"
					@clear="item.clearFun"
					@change="inputChange(item, formData[item.key])"
          clearable
				/>

				<el-input
					v-if="item.type === 'input' && item.isNumber"
					v-model.number="formData[item.key]"
					:placeholder="item.placeholder"
					:disabled="item.readonly"
					:type="item.password ? 'password' : 'text'"
          clearable
				/>

				<el-input
					v-if="item.type === 'textarea'"
					v-model="formData[item.key]"
					:rows="item.row || 10"
					:placeholder="item.placeholder"
					type="textarea"
          clearable
				/>
				<el-select
					v-if="item.type === 'select' && !item.clear"
					v-model="formData[item.key]"
					clearable
					:placeholder="item.placeholder"
					@change="handleChange(item, formData[item.key])"

				>
					<el-option
						v-for="o in item.option"
						:key="o.value"
						:label="o.label"
						:value="o.value"
					/>
				</el-select>
				<el-select
					v-if="item.type === 'selectFilterable' && item.clear"
					v-model="formData[item.key]"
					clearable
					filterable
          :disabled="item.disabled"
					:placeholder="item.placeholder"
					@change="handleChange(item, formData[item.key])"
					@clear="item.clearFun"
				>
					<el-option
						v-for="o in item.option"
						:key="o.value"
						:label="o.label"
						:value="o.value"
					/>
				</el-select>
				<el-select
					v-if="item.type === 'select' && item.clear"
					v-model="formData[item.key]"
					clearable
					:placeholder="item.placeholder"
					@change="handleChange(item, formData[item.key])"
					@clear="item.clearFun"
				>
					<el-option
						v-for="o in item.option"
						:key="o.value"
						:label="o.label"
						:value="o.value"
					/>
				</el-select>

				<el-radio-group
					v-if="item.type === 'radio'"
					v-model="formData[item.key]"
				>
					<el-radio
						v-for="o in item.option"
						:key="o.value"
						:label="o.value"
					>
						{{ o.label }}
					</el-radio>
				</el-radio-group>

				<el-switch
					v-if="item.type === 'switch'"
					v-model="formData[item.key]"
					@change="switchChange(item, formData[item.key])"
					>
				</el-switch>

				<el-checkbox-group
					v-if="item.type === 'checkbox'"
					v-model="formData[item.key]"
					clearable
				>
					<el-checkbox
						v-for="o in item.option"
						:key="o.value"
						:disabled="o.disabled"
						:label="o.value"
					>
						{{ o.label }}
					</el-checkbox>
				</el-checkbox-group>

				<el-date-picker
					v-if="item.type === 'daterange'"
					v-model="formData[item.key]"
					:value-format="item.valueFormat || 'yyyy-MM-dd'"
					type="daterange"
					:default-time="['00:00:00', '23:59:59']"
					range-separator="~"
					start-placeholder="起始日期"
					end-placeholder="截止日期"
					clearable
				/>

				<el-date-picker
					v-if="item.type === 'date'"
					v-model="formData[item.key]"
					:value-format="item.valueFormat || 'yyyy-MM-dd'"
					type="date"
					:placeholder="item.placeholder"
          			clearable
				/>
				<el-date-picker
					v-if="item.type === 'disabledDate'"
					v-model="formData[item.key]"
					:value-format="item.valueFormat || 'yyyy-MM-dd'"
					type="date"
					:placeholder="item.placeholder"
					:picker-options="item.pickerOptions"
          			clearable
				/>
				<el-date-picker
					v-if="item.type === 'pickerOptions'"
					v-model="formData[item.key]"
					type="daterange"
					:picker-options="item.pickerOptions"
					:editable="false"
					start-placeholder="开始日期"
					range-separator="~"
					end-placeholder="结束日期"
					:clearable="false"
					:default-time="['00:00:00', '23:59:59']"
					>
				</el-date-picker>

				<ElImgUpload
					v-if="item.type === 'upload'"
					:exist-image="formData[item.key]"
					:place-txt="item.placeholder"
					@handleDelete="handleUploadDelete(item.key)"
					@handleChange="handleUploadChange($event, item.key)"
				/>

				<ElImgUploadGroup
					v-if="item.type === 'uploadGroup'"
					:place-txt="item.type === 'uploadGroup' ? item.placeholder : ''"
					:length="uploadLength"
					:exist-img-list="formData[item.key]"
					@handleChange="handleUploadGroupChange($event, item.key)"
				/>
			</el-form-item>

			<el-form-item style="margin-left: 10px;">
				<slot name="btnSlot" />
				<el-button :loading="isLoading" type="primary" :round="round" @click="handleConfirm">
					{{ confirmBtnName }}
				</el-button>
      </el-form-item>
	  <el-form-item style="float:right">
				<slot name="AddSlot" />
      </el-form-item>
		</el-form>
	</div>
</template>

<script>
import ElImgUpload from './elements/upload-element'
import ElImgUploadGroup from './elements/upload-group-element'

export default {
	name: 'GlobalForm',
	components: {
		ElImgUpload,
		ElImgUploadGroup
	},
	props: {
		labelWidth: {
			type: Number,
			default: 120
		},
		inline: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: 'small'
		},
		// 验证规则，参考element
		formRules: {
			type: Object,
			default () {
				return {}
			}
		},
		// 表单子项列表
		formItemList: {
			type: Array,
			required: true
		},
		// 初始化数据
		initData: {
			type: Object,
			default () {
				return null
			}
		},

		confirmBtnName: {
			type: String,
			default: '提交'
		},

		needBtnLoading: {
			type: Boolean,
			default: false
		},
		round: {
			type: Boolean,
			default: false
		},
		uploadLength: {
			type: Number,
		}
	},
	data () {
		return {
			isLoading: false,
			formData: {},
			deleteArr: []
		}
	},
	created () {
		if (this.initData) {
			this.formData = this.initData
			return
		}
		this.formItemList.forEach(n => {
			if (n.type === 'checkbox') {
				this.$set(this.formData, n.key, [])
			} else {
				this.$set(this.formData, n.key, '')
			}
		})
	},
	methods: {
		// 如果删除图片
		handleUploadDelete (key) {
			this.formData[key] = ''
		},

		// 上传图片后，将图片信息保存到对应参数中
		handleUploadChange (url, key) {
			this.formData[key] = url
		},

		// 上传图片后，将图片信息保存到对应参数中
		handleUploadGroupChange (list, key) {
			if (Array.isArray(list)) {
				this.formData[key] = list
			}
		},
		handleConfirm () {
			// 将所有的值加入验证
			this.$refs['validForm'].validate(valid => {
				if (valid) {
					// 如果通过验证 是否需要loading
					if (this.needBtnLoading) {
						this.isLoading = true
					}
					this.$emit('handleConfirm', this.formData, this.completed)
				}
			})
		},

		// 取消loading
		completed () {
			this.isLoading = false
		},
		inputChange(item, value){
			this.$emit('inputChange',item,value)
		},

		handleChange (item, value) {
			this.$emit('handleChange',item,value)
			this.deleteArr = []
			item.option.forEach(e => {
				if (value === e.value) {
					if (e.filter) {
						this.deleteArr.push(...e.filter)
					}
				}
			});
      //一个下拉影响另一个下拉的值,父子关系
      // 在父变化时，那么需要清除原本子key的值，父key中存在createStoreName字段，代表子key的值，在本子组件使用
      // 当父key没有值，子key禁用，这部在父组件中使用
      if (item.childrenKey in this.formData) {
        this.formData[item.childrenKey] = null;
      }
      if(item.childrenKey2 in this.formData){
        this.formData[item.childrenKey2] = null;
      }
		},
		switchChange (item, value) {
			this.deleteArr = []
			console.log(value);
		}
	}
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.el-form-group{}
</style>
