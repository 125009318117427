<template>
  <div class="GlobalInfoBar">
    <div v-if="type === 'common'" class="info-bar-container">
      <div class="info-bar">
        <!--商店image-->
        <img v-if="icon==='shop'" class="info-bar_icon" src="@/assets/images/icon_sjlb_@2x.png" alt />
        <!--回收image-->
        <img v-if="icon==='recycle'" class="info-bar_icon" src="@/assets/images/icon_hs__@2x.png" alt />
        <slot></slot>
        <div class="info-bar_content">
          <h5>{{title}}</h5>
          <p>{{content}}<span v-if="contentOn" class="text-on">{{contentOn}}</span></p>
        </div>
      </div>
    </div>
    <div v-if="type === 'hint'" class="info-bar-container hint-container">
      <div class="info-bar">
        <img class="info-bar_icon" src="@/assets/images/icon_th@2x.png" alt />
        <div class="info-bar_content">
          <h5>{{title}}</h5>
          <p>{{content}}</p>
        </div>
        <div class="iphone-info">
          <!--<img class="iphone-info_icon" src="@/assets/images/icon_th@2x.png" alt />-->
          <img class="iphone-info_icon" src="@/assets/images/icon_lxgl@2x.png" alt />
          <span>联系管理员</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalInfoBar',
  props: {
    title: String,
    content: String,
    contentOn: String,
    // type: common/hint
    type: {
      type: String,
      default: 'common'
    },
    // icon: shop/recycle
    icon: {
      type: String,
      default: 'shop'
    }
  },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.info-bar-container {
  margin-bottom: 20px;
  .info-bar {
    padding: 10px 20px 10px 20px;
    background: #E4ECFD;
    border-radius: 33px;
    display: flex;
    align-items: center;
    &_icon {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }

    &_content {
      > h5{
        font-size: 18px;
        font-weight: 700;
        color: #242D3E;
        line-height: 20px;
        margin-bottom: 5px;
      }
      > p {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .text-on{
    color: #FF687B;
  }
}

.hint-container{
  .info-bar {
    padding: 4px 10px 4px 10px;
    background: #FFEBEB;
    border-radius: 25px;
    position: relative;
    &_icon {
      width: 22px;
      height: 22px;
      margin-right: 11px;
    }

    &_content {
      > h5{
        font-size: 14px;
        font-weight: 400;
        color: #FF8080;
        margin-bottom: 0;
      }
      > p {
        font-size: 14px;
        font-weight: 400;
        color: #242D3E;
      }
    }

    .iphone-info{
      position: absolute; right: 22px;
      font-size: 14px;
      font-weight: 400;
      color: #242D3E;
      display: flex; align-items: center;
      &_icon{
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }
  }
}
</style>
