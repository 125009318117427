import Layout from '@/layout/layout'

const RecallOrderRouter = {
    path: '/RecallOrder',
    component: Layout,
    redirect: 'noRedirect',
    name: '回收订单管理',
    children: [
        {
            path: 'index',
            component: () => import('@/views/RecallOrder/index'),
            name: '订单列表',
            meta: {title: '订单列表', noCache: true}
        },
        {
            path:"PayBill",
            component:()=>import("@/views/RecallOrder/PaybBill/index"),
            name: '支付账单',
            meta: {
                title: "支付账单",
                noCache: true
            }
        }
    ]
}

export default RecallOrderRouter;
