import Layout from '@/layout/layout'

const classificationRouter = {
    path: '/profitSplit',
    component: Layout,
    redirect: 'noRedirect',
    name: '订单分润',
    children: [
        {
            path: 'Order',
            component: () => import('@/views/profitSplit/order/index'),
            name: '分润账单',
            meta: { title: '分润账单', noCache: true }
        },
        {
            path: 'orderDetails',
            component: () => import('@/views/RecallOrder/Details'),
            name: '订单详情',
            meta: { title: '订单详情', noCache: true }
        }
    ]
}

export default classificationRouter