import Layout from '@/layout/layout'

const featureRouter = {
  path: '',
  component: Layout,
  redirect: 'noRedirect',
  name: '',
  children: [
    {
      path: 'dashboard',
      component: () => import('@/views/home/home'),
      name: '首页',
      meta: { title: '首页', noCache: true, fixed: true }
    },
    {
      path: 'storeHome',
      component: () => import('@/views/storeHome/home'),
      name: '门店首页',
      meta: { title: '门店首页', noCache: true}
    },
    {
      path: 'storeRatio',
      component: () => import('@/views/dataStatistics/index'),
      name: '环比数据',
      meta: { title: '环比数据', noCache: true}
    },
    // {
    //   path: 'demo',
    //   component: () => import('@/views/demo'),
    //   name: '组件演示',
    //   meta: { title: '组件演示', noCache: true }
    // }
  ]
}

export default featureRouter
