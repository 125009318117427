import axios from 'axios'
import environments from '../../config/url'
import {
	MessageBox,
	Message
} from 'element-ui'
import router from '../../router'
import {
	deleteUserInfo,
	setSessionStorage
} from '../../utils/storage'
import { encryptByDES, decryptByDES } from './../3DES'
// import qs from 'qs' 
console.log(process.env)
const instance = axios.create({
    baseURL:environments[process.env.VUE_APP_BASE_URL].baseURL,
    // timeout:50*1000,
})

//请求拦截器
instance.interceptors.request.use(config=>{
    // console.log(config)
    // if(config.method === 'post'){
    //     // config.data = qs.stringify(config.data)
    // }
    const token = localStorage.getItem('token');
	if(token){
		let TOKEN = encryptByDES(encryptByDES((new Date().getTime()).toString(),'63e42c2444e94c1ebca21d30d2aa39a5'),token)
		config.headers.TOKEN = TOKEN;
	}
	config.headers.ACCESS_TOKEN = token;
    // if (token) {
    //     // 添加headers
    //     config.headers.ACCESS_TOKEN = sessionStorage.getItem("token");
    //     config.headers['content-type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    //   }
    return config;
 })

//  instance.interceptors.response.use(response => {
//     return response.data;
//   });
// response 拦截
instance.interceptors.response.use(
	response => {
    // return response.data;
		// 请求写在api的
		if (response.data.code === 1 || response.data.size) {
			return response.data
		} else {
			Message.error(response.data.msg)
			return Promise.reject(response.data)
		}
	},

	error => {
		if (!error.response) {
			MessageBox.alert(error.message, '错误提示', {
				type: 'error'
			})
		}
		if (error.code === 'ECONNABORTED') {
			MessageBox.alert('数据请求超时，请重新请求', '错误提示', {
					type: 'error'
				})
				.then(() => {
					return instance(error.config)
				})
		} else {
			if (error.response.status === 401) {
				if (router.currentRoute.fullPath !== '/login') {
					console.log(router.currentRoute.fullPath)
					setSessionStorage(router.currentRoute.fullPath)
					deleteUserInfo()
				}
				router.replace('/login')
			} else if (error.response.status === 400) {
				MessageBox.alert(error.response.data, '错误提示', {
					type: 'error'
				})
			} else{
				MessageBox.alert(error.response.status + '服务繁忙', '错误提示', {
					type: 'error'
				})
				console.log(error.response.data)
			}
		}
		return Promise.reject(error)
	}
)

export default instance