export default {
    data() {
        return {
            loading: false,
            page: {
                pageNum: 1,
                pageSize: 10,
                total: 0,
                isLastPage: false
            }
        }
    }
}
