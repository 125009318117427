<template>
  <el-aside id="aside" class="aside" :width="`${isCollapse ? '64' : '210'}px`">
    <el-menu
      :collapse="isCollapse"
      :default-active="defaultActive"
      :default-openeds="defaultOpeneds"
      background-color="#273655"
      text-color="#C2C6CD"
      active-text-color="#ffffff"
    >
      <div v-for="tab in routersList" :key="tab.path">
        <el-menu-item
          v-if="tab.type === 'menu-item'"
          :index="tab.path + ''"
          @click="tab.method || routerLinks(tab.path)"
        >
          <!-- <img class="mr-10 menu_icon" :src="bingActiveIcon(tab.icon)"  v-if="$route.path == tab.path"  /> -->
          <img class="mr-10 menu_icon" :src="bingIcon(tab.menuIcon)" alt="" srcset=""/>
          <span slot="title" style="font-size: 16px">{{ tab.name }}</span>
        </el-menu-item>

        <el-submenu v-if="tab.type === 'submenu'" :index="tab.path + ''">
          <template slot="title">
            <img class="mr-10 menu_icon" :src="bingIcon(tab.menuIcon)" alt="" srcset="" />
            <span v-show="!isCollapse" slot="title">{{ tab.name }}</span>
          </template>
          <div v-for="item in tab.children" :key="item.path">
            <el-menu-item
              v-if="!item.method"
              :index="item.path + ''"
              @click="routerLinks(item.path)"
            >
              {{ item.name }}
            </el-menu-item>
            <!--使用三目运算不能正常触发method，改成if-->
            <el-menu-item v-else :index="item.path" @click="item.method">
              {{ item.name }}
            </el-menu-item>
          </div>
        </el-submenu>
      </div>
    </el-menu>
  </el-aside>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Aside",
  data() {
    return {
      /*
       * type: 'menu-item'(单个菜单)、'submenu'(菜单组)
       * */
      routersList:[],
      defaultActive: this.activeTab,
      // 默认打开的tabs: ['/feature']
      defaultOpeneds: [],
    };
  },
  created() {
    this.getMenuList();
  },
  mounted() {
    const path = this.$route.path;
    console.log(path);
    if (path.indexOf("/feature/TabsView") === -1) {
      this.defaultActive = path;
    } else {
      this.defaultActive = "/feature/TabsView";
    }
  },
  computed: {
    isCollapse() {
      return this.$store.state.tagsView.isCollapse;
    },
    routerPath() {
      return this.$store.state.tagsView.routerPath
    },
  },
  watch: {
    routerPath(path){
      console.log(path);
      if (path.indexOf("/feature/TabsView") === -1) {
        this.defaultActive = path;
      } else {
        this.defaultActive = "/feature/TabsView";
      }
    }
  },
  methods: {
    bingIcon(icon) {
      icon = icon || "default";
      let reg = icon.indexOf('http')
      return reg>=0?icon:require(`@/assets/menu/${icon}.png`);
    },
    bingActiveIcon(icon) {
      icon = icon || "default";
      let reg = icon.indexOf('http')
      return reg>=0?icon:require(`@/assets/menu/${icon}_hover.png`);
    },
    routerLinks(path) {
      console.log(path);

      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    getMenuList() {
      _api.getMenuList().then((res) => {
        this.$store.commit('tagsView/MENU_LIST', res.data)
        res.data.forEach(item => {
          item.name = item.menuName
          item.path = item.menuUrl
          if(item.children && item.children.length != 0){
            item.type = 'submenu'
            item.children.forEach(ele => {
              ele.name = ele.menuName
              ele.path = ele.menuUrl
            })
          }else{
            item.type = 'menu-item'
          }
        });
        console.log(res.data);
        this.routersList = res.data
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.aside {
  background: #273655;
  border-radius: 0px 20px 20px 0px;
  transition: width 0.3s;
}
.mr-10{
  margin-right: 10px;
}
.menu_icon{
  width: 16px;
  height: 16px;
}
</style>
