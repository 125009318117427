const state = {
	visitedViews: [],
	isCollapse: false,
	// breadcrumbList -> demo
	// [
	// 	{
	// 		path: '/demo/demo-demo',
	// 		name: 'demo'
	// 	},
	// 	{
	// 		path: '',
	// 		name: 'demo'
	// 	}
	// ]
	http_response_success_code: 1,
	pages:"",
	ApplyTabId:"",
	seachTotal:0,
	SeachParams:Object,
	dialogParams:Object,
	menuList:[],//菜单数组
	HomeDataList:[],//首页数据
	MerchantDataList:[],//merchant页面商家列表表格数组
	MerchantFormData:[],//merchant页面商家列表编辑数组
	ResidentDataList:[],//Resident页面表格数组
	ResidentFormData:[],//Resident页面formdata（跳转详情数组）
	orderDetailsList:[],//订单页面详情数组
	StaffDataList:[],//系统管理Staff页面表格数组
	StaffFormData:[],//Resident页面formdata(跳转详情数组)
	RoleinfoData:[],//角色管理Role页面Formdata(跳转详情数据)
	selectProvinceId:"",
	selectCityId:"",
	selectRegionId:"",
	breadcrumbList: [],
	imgDataNew:[],
	routerPath:"",
	storeList:[],

}

const mutations = {
	MENU_LIST:(stete,isCollapse) =>{
		stete.menuList = isCollapse
	},
	SELECT_PROVINCED:(state,isCollapse) => {
		state.selectProvinceId = isCollapse
	},
	SELECT_CITYID:(state,isCollapse) => {
		state.selectCityId = isCollapse
	},
	SELECT_REGIONID:(state,isCollapse) => {
		state.selectRegionId = isCollapse
	},
	IMG_DATALIST:(state,isCollapse) => {
		state.imgDataNew = isCollapse
	},
	POST_HOMEDATALIST: (state, isCollapse) => {
		state.HomeDataList = isCollapse
	},
	SEACH_PARAMS: (state, isCollapse) => {
		state.SeachParams = isCollapse
	},
	ROUTER_PATH: (state, isCollapse) => {
		state.routerPath = isCollapse
	},
	ADD_VISITED_VIEW: (state, view) => {
		let hasRouter = false
		state.visitedViews.forEach(v => {
			if (v.path === view.path) {
				v.pathUrl = view.fullPath
				hasRouter = true
				return v.path === view.path

			}
		})

		if (hasRouter) {
			return
		}

		let queryKeys = Object.keys(view.query)
		let pathUrl = view.path
		if (queryKeys.length) {
			pathUrl += '?'
			queryKeys.forEach(item => {
				pathUrl += `${item}=${view.query[item]}&`
			})
			// console.log(pathUrl.substring(0, pathUrl.lastIndexOf('&')))
			pathUrl = pathUrl.substring(0, pathUrl.lastIndexOf('&'))
		}
		// let title = view.meta.title ? view.meta.title.slice(view.meta.title.indexOf('-') + 2) : 'no-name'
		let title = view.meta.title ? view.meta.title : 'no-name'
		let newView = {
			meta: view.meta,
			path: view.path,
			pathUrl: pathUrl
		}
		state.visitedViews.push(
			Object.assign({}, newView, {
				title: title
			}))
	},

	DEL_VISITED_VIEW: (state, view) => {
		for (const [i, v] of state.visitedViews.entries()) {
			if (v.path === view.path) {
				state.visitedViews.splice(i, 1)
				break
			}
		}
	},

	DEL_OTHER_VIEW: (state, view) => {
		state.visitedViews = state.visitedViews.filter(v => {
			return v.meta.fixed || v.path === view.path
		})
	},

	DEL_ALL_VIEW: (state) => {
		state.visitedViews = state.visitedViews.filter(v => {
			return v.meta.fixed
		})
	},

	SET_ISCOLLAPSE: (state, isCollapse) => {
		state.isCollapse = isCollapse
	},
	//商家列表弹窗参数
	DIALOG_PARAMS: (state, isCollapse) => {
		state.dialogParams = isCollapse
	},
	//merchant页面商家列表表格数组
	POST_APPLYTABID: (state, isCollapse) => {
		state.ApplyTabId = isCollapse
	},
	//merchant页面表格数组
	POST_MERCHANTDATA: (state, isCollapse) => {
		state.MerchantDataList = isCollapse
	},
	POST_TOTAL: (state, isCollapse) => {
		state.seachTotal = isCollapse
	},
	//merchant页面编辑详情数组
	POST_MERCHANT_FORMDATA: (state, isCollapse) => {
		state.MerchantFormData = isCollapse
	},
	//Resident页面表格数组
	POST_RESIDENT: (state, isCollapse) => {
		state.ResidentDataList = isCollapse
	},
	//Resident页面formdata（跳转详情数据）
	POST_RESIDENT_FORMDATA: (state, isCollapse) => {
		state.ResidentFormData = isCollapse
	},
	//系统管理Staff页面表格数组
	POST_STAFF: (state, isCollapse) => {
		state.StaffDataList = isCollapse
	},
	//系统管理Staff页面Formdata(跳转详情数据)
	POST_STAFFADD_FORMDATA: (state, isCollapse) => {
		state.StaffFormData = isCollapse
	},
	//角色管理Role页面Formdata(跳转详情数据)
	POST_ROLEINFORES: (state, isCollapse) => {
		state.RoleinfoData = isCollapse
	},
	//订单页面详情数组
	GET_ORDERDETAILS: (state, isCollapse) => {
		state.orderDetailsList = isCollapse
	},
	// 设置路由导航名称
	SET_TabBar_NAME: (state, view) => {
		// console.log('设置路由导航名称', state, view)
		let list = []
		const currentItem = view.matched.find(item => item.path === view.path)
		if (currentItem.parent && currentItem.parent.name) {
			list.push({
				path: currentItem.parent.redirect !== 'noRedirect' ? currentItem.parent.path : '',
				name: currentItem.parent.name
			})
		}
		list.push({
			name: view.name
		})
		state.breadcrumbList = list
	},
	//门店列表
	SET_STORELIST: (state, isCollapse) => {
		state.storeList = isCollapse
	},
}

const actions = {
	ClearSeachParams({
		commit
	}, view) {
		commit('SEACH_PARAMS', view)
	},
	addView({
		commit
	}, view) {
		commit('ADD_VISITED_VIEW', view)
	},
	delVisitedView({
		commit,
		state
	}, view) {
		return new Promise(resolve => {
			commit('DEL_VISITED_VIEW', view)
			resolve([...state.visitedViews])
		})
	},
	delOtherView({
		commit
	}, view) {
		commit('DEL_OTHER_VIEW', view)
	},
	delAllView({
		commit
	}) {
		commit('DEL_ALL_VIEW')
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
