<template>
   <el-dialog
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    @close="QualityDialogClose"
    :width="`${width}px`"
>
    <slot></slot>
    <div slot="footer" class="dialog-footer">
        <el-button @click="QualityDialogClose">{{closeBtn}}</el-button>
        <el-button type="primary" @click="QualityDialogSub" v-if="successBtnVisible">{{successBtn}}</el-button>
    </div>
</el-dialog>
</template>

<script>
export default {
  name: 'DialongHome',
  props: {
        QualityDialogFlag: {
          default: Boolean,
        },
        title: String,
        closeBtn:String,
        successBtn:String,
        successBtnVisible:Boolean,
        width:String
    },
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        QualityDialogClose() {
            this.$emit("QualityDialogClose");
        },
        QualityDialogSub() {
            this.$emit("QualityDialogSub");
        }
    },
    watch: {
        QualityDialogFlag() {
            this.visible = this.QualityDialogFlag;
        },
    },
    created() {
 
    },
    mounted() {

    },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">

</style>
