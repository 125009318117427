import Layout from '@/layout/layout'

const merchantRouter = {
    path: "/merchant",
    component: Layout,
    redirect: 'noRedirect',
    name: "渠道商户管理",
    children: [
        {
            path: 'StoreShop',
            name: "商户列表",
            component: () => import("@/views/StoreShop/index.vue"),
            meta: {
                title: "商户列表",
                noCache: true
            }
        }
    ],
}

export default merchantRouter
